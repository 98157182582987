<template>
  <div>
    <RegisterForm></RegisterForm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RegisterForm from "@/components/SmartVMS/RegisterForm";

export default {
  data: function () {
    return {};
  },
  components: {
    RegisterForm
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser"
    })
  },
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
